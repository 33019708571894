import React from 'react'
import LoginEmail from './LoginEmail'
import { Router } from '@routes'
import { UserContext } from '@context/UserContext'
import GoogleLoginButton from '@components/SocialLogin/GoogleLoginButton'
import { createAlert, userAlerts } from '@lib/api-services/authService'
import { createFavouriteFromLocalstore } from '@lib/helpers'
import { ACTIVEPIPE_COOKIE, setCookie } from '@lib/cookies'

import * as S from './Login.styled'
import HeroSection from '@global/Blocks/Hero'
import useBodyClass from '@hooks/useBodyClass'

const Login = (props) => {
  const { title, text, links } = props
  useBodyClass('modal-open', false)

  const loginAndRedirect = async ({ token, user }) => {
    const { setToken, setProfile, returnto, setAlerts } = props
    await setToken(token)
    await setProfile(user)

    /*
      Create alert/favourite from localstorage data if have
    */
    const saveSearchParams = localStorage.getItem('saveSearchParams')
    if (saveSearchParams) {
      const alertResponse = await createAlert(
        token,
        JSON.parse(saveSearchParams)
      )
      if (!alertResponse.error) {
        const alertResponse = await userAlerts(token)
        setAlerts(alertResponse.results)
      }
      localStorage.removeItem('saveSearchParams')
    }

    await createFavouriteFromLocalstore(token)
    if (Router.router.query.redirect) {
      Router.pushRoute(Router.router.query.redirect)
    } else if (user && user.subscriber) {
      if (returnto && returnto.path)
        Router.pushRoute(returnto.path).then(
          () => window && window.scrollTo(0, 0)
        )
      else {
        setCookie(ACTIVEPIPE_COOKIE, true, 36500) // expires in 100 years
        Router.pushRoute('/my-account')
      }
    }
  }

  return (
    <>
      <HeroSection
        title={title}
        bgImageUrl={'/imgs/background-login.jpg'}
        titlePosition='bottom'
        gradientPosition='bottom'
      />

      <S.FormContainer>
        <S.ContainerLeft>
          <S.Title>{title}</S.Title>
          <S.Description>{text}</S.Description>
        </S.ContainerLeft>

        <S.ContainerRight>
          <S.SocicalLogin>
            <GoogleLoginButton
              onLogin={loginAndRedirect}
              buttonText={links.google.label}
            />
          </S.SocicalLogin>
          <S.Line>
            <S.LineText>or via Email</S.LineText>
          </S.Line>
          <S.Form>
            <LoginEmail />
          </S.Form>
        </S.ContainerRight>
      </S.FormContainer>
    </>
  )
}

const ContextWrappedLogin = (props) => (
  <UserContext.Consumer>
    {(context) => <Login {...props} {...context} />}
  </UserContext.Consumer>
)

ContextWrappedLogin.defaultProps = {
  title: 'WELCOME',
  text: 'Your personal Barry Plant account allows you to remember your preferred office, save search results, bookmark properties, and gain access to exclusive listings.',
  links: {
    facebook: {
      label: 'login with facebook',
      link: '#',
    },
    google: {
      label: 'login with google',
      link: '#',
    },
    email: {
      label: 'login with email',
      link: '#',
    },
    register: {
      label: 'Register',
      link: '/create-account/',
    },
  },
}

export default ContextWrappedLogin
