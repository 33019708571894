import { useGoogleLogin } from '@react-oauth/google'
import { loginGoogle } from '@lib/api-services/authService'
import { toast } from 'react-toastify'
import { logEvent } from '@lib/analyticsService'
import Google from '@icons/Google'

import * as S from './SocialLogin.styled'

const GoogleLoginButton = ({ buttonText, onLogin, onFailed, size=7}) => {
  const login = useGoogleLogin({
    onSuccess: async res => {
      const googleResponse = await loginGoogle(res.access_token)
      if (googleResponse.ok) {
        // GA: log account creation in google analytics
        logEvent('Forms', 'Create account', 'Google')
        onLogin && onLogin(googleResponse)
      } else {
        toast.error(googleResponse.non_field_errors[0])
      }
    },
    onError: resp => {
      onFailed && onFailed(resp)
    }
  })

  return (
    <S.SocialButton 
      startIcon={<Google />}
      onClick={login}>
        {buttonText}
    </S.SocialButton>
  )
}

export default GoogleLoginButton
