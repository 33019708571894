import styled, { css } from 'styled-components'
import Hero from "@global/Blocks/Hero";
import { DEVICES } from '@constants/global'
import { FONTS, text2XL, text3XL, text4XL, textLG, textBase, textSM } from '@styles/fonts'
import { COLORS } from '@styles/colors'
import { Link } from '@routes'

export const LoginHero = styled(Hero)`
  > div{
    max-height: 600px!important;
  }
`

export const FormContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 86px;
  padding: 64px 43px;

  @media ${DEVICES.mobile} {
    flex-direction: column;
    gap: 60px;
    padding: 40px 16px;
    margin-bottom: 60px;
  }
`

export const ContainerLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const Title = styled.h3`
  ${text3XL};
  font-family: ${FONTS.medium};
  font-style: normal;
  font-weight: 420;
  letter-spacing: 0.1px;
  text-transform: inherit;

  @media ${DEVICES.mobile} {
    ${text2XL};
  }
`

export const Description = styled.p`
  ${textBase};
  font-family: ${FONTS.regular};
  font-style: normal;
  font-weight: 390;
  letter-spacing: 0.1px;

  @media ${DEVICES.mobile} {
    color: ${COLORS.secondary};
  }
`

export const ContainerRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 28px;
`

export const SocicalLogin = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;

  button {
    width: 100%;
  }

  @media ${DEVICES.mobile} {
    flex-direction: column;
  }
`

export const Line = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #E2E2E5;
  line-height: 0.1em;
  margin: 10px 0;
`

export const LineText = styled.span`
  background-color: ${COLORS.white};
  color: ${COLORS.secondary};
  font-family: ${FONTS.regular};
  padding: 0 10px;
  font-style: normal;
  font-weight: 390;
  letter-spacing: 0.1px;
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 8px;
`


export const RememberMeAndForgot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SubActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`

export const TextLink = styled.span`
  ${textSM};
  color: ${COLORS.navi};
  font-family: ${FONTS.regular};
  font-style: normal;
  font-weight: 390;
  letter-spacing: 0.1px;
`

export const CustomLink = styled(Link)`
  ${textBase};
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  font-style: normal;
  font-weight: 420;
  letter-spacing: 0.1px;
  text-decoration-line: underline;

  ${({ size }) =>
  size && size ==='small' &&
  css`
    ${textSM};
  `}
`

export const Resend = styled.span`
  ${textBase};
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  font-style: normal;
  font-weight: 420;
  letter-spacing: 0.1px;
  text-decoration-line: underline;

  &:hover {
    cursor: pointer;
  }

  ${({ size }) =>
  size && size ==='small' &&
  css`
    ${textSM};
  `}
`
export const ErrorList = styled.ul`
  color: red;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const ErrorItem = styled.li``;