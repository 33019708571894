import { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { UserContext } from '@context/UserContext'
import { createAlert, login, userAlerts } from '@lib/api-services/authService'
import { Router } from '@routes'
import * as yup from 'yup'
import { createFavouriteFromLocalstore } from '@lib/helpers'
import { ACTIVEPIPE_COOKIE, setCookie, getCookie } from '@lib/cookies'
import Field from '@global/FormInput/Field'
import Button from '@global/Button'
import * as S from './Login.styled'

const LoginEmailForm = ({
  title,
  copy,
  copySmall,
  form,
  register,
  returnto,
  ...props
}) => {
  const [loginErrors, setLoginErrors] = useState(null)

  // form validation schema
  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup.string().required('Password is required'),
  })

  useEffect(() => {
    // Check if token exists
    const token = getCookie('jwt')
    if (token) {
      Router.pushRoute('/my-account')
    }
  }, [])

  const handleSubmit = async (values) => {
    const { setToken, setProfile, setAlerts } = props
    const response = await login(values.email, values.password)

    if (response.token) {
      await setToken(response.token)
      await setProfile(response.user)

      /*
        Create alert/favourite from localstorage data if have
      */
      const params = localStorage.getItem('saveSearchParams')
      if (params) {
        const alertResponse = await createAlert(
          response.token,
          JSON.parse(params)
        )
        if (!alertResponse.error) {
          const alertResponse = await userAlerts(response.token)
          setAlerts(alertResponse.results)
        }
        localStorage.removeItem('saveSearchParams')
      }
      await createFavouriteFromLocalstore(response.token)

      if (Router.router.query.redirect) {
        Router.pushRoute(Router.router.query.redirect)
      } else if (response.user && response.user.subscriber) {
        setLoginErrors(null)
        if (Router.router.query.redirect) {
          Router.pushRoute(Router.router.query.redirect)
        } else if (returnto && returnto.path)
          Router.pushRoute(returnto.path).then(
            () => window && window.scrollTo(0, 0)
          )
        else {
          setCookie(ACTIVEPIPE_COOKIE, true, 36500) // expires in 100 years
          Router.pushRoute('/my-account')
        }
      }
    } else if (response.non_field_errors) {
      setLoginErrors(response.non_field_errors)
    }
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, handleSubmit, getFieldProps }) => (
        <form onSubmit={handleSubmit} noValidate>
          <S.Form>
            <Field
              label={'Email Address'}
              required
              {...getFieldProps('email')}
              error={touched.email && errors.email}
            />
            <Field
              label={'Password'}
              type={'password'}
              required
              {...getFieldProps('password')}
              error={touched.password && errors.password}
            />
            <S.RememberMeAndForgot>
              <Field
                type='checkbox'
                label='Remember me'
                {...getFieldProps('isRememberme')}
              />
              <S.CustomLink href={`/reset-password`}>
                Forgot your password?
              </S.CustomLink>
            </S.RememberMeAndForgot>

            {loginErrors && (
              <S.ErrorList>
                {loginErrors.map((error, index) => (
                  <S.ErrorItem key={index}>{error}</S.ErrorItem>
                ))}
              </S.ErrorList>
            )}

            <Button type='submit' color='secondary'>
              Login
            </Button>
            <S.SubActionContainer>
              <S.TextLink>Don&apos;t have an account?</S.TextLink>{' '}
              <S.CustomLink href={`/create-account/`} size='small'>
                Sign up now
              </S.CustomLink>
            </S.SubActionContainer>
          </S.Form>
        </form>
      )}
    </Formik>
  )
}

const ContextWrappedLoginEmailForm = (props) => (
  <UserContext.Consumer>
    {(context) => <LoginEmailForm {...props} {...context} />}
  </UserContext.Consumer>
)

ContextWrappedLoginEmailForm.defaultProps = {
  title: 'Login with Email',
  copy: 'Log in to your personal Barry Plant account to remember your nearest or preferred office, save your search results and build your wishlists.',
  copySmall: '',
  closeBtnLabel: '',
  searchBtnLabel: '',
  register: {
    label: 'Register',
    link: '/create-account/',
  },
  form: {
    yourDetails: {
      title: '',
      fields: [
        {
          type: 'email',
          label: 'Email',
          required: true,
          name: 'email',
        },
        {
          type: 'password',
          label: 'Password',
          required: true,
          name: 'password',
        },
      ],
    },
    submit: {
      type: 'submit',
      label: 'login',
      fieldSize: 'u-1/3@palm u-text-center',
    },
  },
}

export default ContextWrappedLoginEmailForm
