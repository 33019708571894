import styled, { css } from 'styled-components'
import Button from '@global/Button'
import { COLORS } from '@styles/colors'
import { FONTS, text3XL, text4XL, textLG, textBase, textSM } from '@styles/fonts'

export const SocialButton = styled(Button)`
  // background-color: #F5F2EB;
  color: ${COLORS.secondary};

  font-family: ${FONTS.bold};
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 18px; /* 150% */
  letter-spacing: 0.8px;
  text-transform: uppercase;

  > svg {
    width: 16px;
    height: 16px;

    &:hover{
      color: ${COLORS.white};
    }
  }
`
